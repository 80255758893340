<template>
  <div>
    <div class="nanfanManagementTop">
      <div class="top_inside">
        <el-row type="flex" class="topTitleBox">
           <el-col :span="6" class="topTitleBox_col">
            <img @click="openNewWeb('https://yd.nfggy.com/')" src="../../../public/static/img/management/pic_nfdj.png" alt />
          </el-col>
          <el-col :span="6" class="topTitleBox_col">
            <img @click="openNewWeb('https://ywglyh.moa.gov.cn/idp/authcenter/ActionAuthChain?entityId=HHGX')" src="../../../public/static/img/management/pic_jxsb.png" alt />
          </el-col>
          <el-col :span="6" class="topTitleBox_col">
            <img @click="openNewWeb('http://202.127.42.178:5911/seedjck/')"
              src="../../../public/static/img/management/pic_xxbs.png" alt />
          </el-col>
          <el-col :span="6" class="topTitleBox_col">
            <img @click="openNewWeb('http://202.127.42.47:8015/Admin.aspx')" src="../../../public/static/img/management/pic_zzsb.png" alt />
          </el-col>
          <!-- <el-col :span="8" class="topTitleBox_col">
            <img @click="toDocument()" src="../../../public/static/img/management/pic_gwgl.png" alt />
          </el-col> -->
          <!-- <el-col :span="8" class="topTitleBox_col">
            <img @click="openNewWeb('http://218.77.183.70:2018/GouZBT2021To23#')" src="../../../public/static/img/management/pic_njbt.png" alt />
          </el-col> -->
        </el-row>
      </div>
    </div>
    <div class="nanfanManagementMain">
      <el-row class="titPublic">
        <el-col :span="16">
          <el-col :span="6" class="titPublic_tag">重点项目</el-col>
          <el-col :span="18" class="titPublic_other">
            <span @click="toDetail()">查看更多</span>
          </el-col>
        </el-col>
        <el-col :span="8">
          <el-col :span="8" class="titPublic_tag">南繁基地概况</el-col>
          <el-col :span="16"></el-col>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-row
            class="importProject"
            v-for="(item,index) in projectList"
            :key="index"
            @click.native="toProject(item.id)"
          >
            <el-col :span="19" class="ip_Detail">{{item.projectTitle}}</el-col>
            <el-col :span="4" class="ip_Time">{{item.updateTime}}</el-col>
            <el-col :span="1"></el-col>
          </el-row>
          <p v-if="projectList.length == 0">暂无数据</p>
        </el-col>
        <el-col :span="8">
          <video
            class="nfmVideo"
            muted
            autoplay="autoplay"
            loop="loop"
            controls="controls"
            src="http://resource.kechuang.cn/nanfan/video/show/12.08-2.mp4"
          />
        </el-col>
      </el-row>
      <el-row class="titPublic">
        <el-col :span="3" class="titPublic_tag">基地管理</el-col>
        <el-col :span="21" class="titPublic_other"></el-col>
      </el-row>
      <el-row>
        <div class="baseBlockTop">
          <div id="mapWrap"></div>
          <div class="baseBlockTop_out">
            <div class="bbto_inside">
              <el-row class="bbto_inside_half">
                <el-col>
                  <p class="bbto_ih_p1">保护区域面积：26.8万亩</p>
                  <p class="bbto_ih_p2">
                    <span class="bbto_ih_t1">核心区面积：5.3万亩</span>
                    <span class="bbto_ih_t2">非核心区面积：21.5万亩</span>
                  </p>
                </el-col>
                <el-col>
                  <p class="bbto_ih_p1">科研育种核心区面积：5.3万亩</p>
                  <p class="bbto_ih_p2">
                    <span class="bbto_ih_t1">现有基地：2.8万亩</span>
                    <span class="bbto_ih_t2">新建基地：2.5万亩</span>
                  </p>
                </el-col>
                <el-col>
                  <p class="bbto_ih_p1">南繁基地总数：677个</p>
                  <p class="bbto_ih_p2">
                    <span class="bbto_ih_t1">核心区基地数：204个</span>
                    <span class="bbto_ih_t2">非核心区基地数：473个</span>
                  </p>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="baseBottomBtn">
          <div class="baseBottomBtn_btn">
            <a href="http://xnzt.nfggy.com/system/varNanfanIndex" target="_blink">南繁成果</a>
          </div>
          <div class="baseBottomBtn_btn" @click="toBaseList">基地成果</div>
        </div>
      </el-row>
      <!-- <el-row class="titPublic">
        <el-col :span="3" class="titPublic_tag">南繁人员</el-col>
        <el-col :span="21" class="titPublic_other">
          <el-button class="titPublic_button" type="primary" plain @click="toApp">进入南繁人员库</el-button>
        </el-col>
      </el-row>
      <el-row>
        <p
          class="nanfanrenyan_p"
        >南繁入库人员基础数据全面分类统计整合业务信息，更好地统筹了解南繁人员全面信息，实现针对人员信息数据的进一步利用，使相关管理部门及时、准确、全面掌握南繁人员基本情况，提高对南繁人员的全面管理、提供优质服务水平，为领导决策提供辅助服务。下载APP进入南繁人员数据库。</p>
        <div id="charts"></div>
        <div class="chartsData">
          <el-row class="chartsData_top">数据更新时间:{{nowTime}}</el-row>
          <el-row class="chartsData_block">
            留守:
            <span>{{stayCount}}</span>人
          </el-row>
          <el-row class="chartsData_block">
            外出:
            <span>{{outCount}}</span>人
          </el-row>
          <el-row class="chartsData_block">
            关注:
            <span>{{focusCount}}</span>人
          </el-row>
        </div>
      </el-row> -->
      <el-row class="titPublic">
        <el-col :span="5" class="titPublic_tag">南繁生物安全监管</el-col>
        <el-col :span="19" class="titPublic_other">
          <span @click="toMonitor()">查看更多</span>
        </el-col>
      </el-row>
      <div class="organismsBox">
        <el-row class="timeDataBox">
          <el-col :span="8">
            <el-date-picker
              v-model="timeData"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="monitoringdata"
            ></el-date-picker>
          </el-col>
          <el-col :span="8">
            <el-radio-group v-model="radio" @change="handleCheckAllChange">
              <el-radio :label="1">全部</el-radio>
              <el-radio :label="2">近一周</el-radio>
              <el-radio :label="3">近一月</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <div class="organismsDetail clearfix">
          <div class="od_block" v-for="(item,index) in monitoring" :key="index">
            <div class="od_block_inside">
              <el-row class="odbi_half">
                <div class="odbih_imgBox">
                  <span>{{item.num}}</span>起
                </div>
                <div class="odbih_imgBox">
                  <img :src="item.img" alt />
                </div>
              </el-row>
              <el-row class="odbi_half">{{item.name}}</el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/nanFanManagement/nanFanManagement.css";
import AMap from "@/utils/AMap.js";
import Vue from "vue";
import { Row, Col, DatePicker } from "element-ui";

Vue.use(Row)
  .use(Col)
  .use(DatePicker);

export default {
  name: "nanfanManagement",
  data() {
    return {
      timeData: "",
      startDates: "",
      endDates: "",
      monitoring: "",
      projectList: "",
      stayCount: "",
      outCount: "",
      focusCount: "",
      totalCount: "",
      nowTime: "",
      namelist: [],
      countlist: [],
      radio: 1,
      map: null,
      resMap: null,
      zoom: 8, // 地图层级
      mapCenter: [109.605279, 19.399066],
      startTime: null,
      pageName: "南繁管理(nanFanManagement.vue)"
    };
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");

    this.nowTime = this.timestampToTime();
    this.selectMonitorSurvey();
    this.getKeyProgramAppByPage();
    // this.queryForEchart();
    this.getbaseDetailList();
    this.initAMap();
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
          .then(response => {
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestSuccessTime", requestTime);
            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "success",
                requestResult: response.toString()
              }
            })
          })
          .catch(error=>{
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestErrorTime", requestTime);

            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "error",
                requestResult: error.toString()
              }
            })
          })
    },
    openNewWeb(address) {
      window.open(address);
    },
    handleCheckAllChange(val) {
      switch (val) {
        case 1:
          this.startDates = "";
          this.endDates = "";
          this.timeData = "";
          this.selectMonitorSurvey();
          break;
        case 2:
          this.startDates = this.getDay(-7);
          this.endDates = this.timestampToTime();
          this.timeData = "";
          this.selectMonitorSurvey();
          break;
        case 3:
          this.startDates = this.getDay(-30);
          this.endDates = this.timestampToTime();
          this.timeData = "";
          this.selectMonitorSurvey();
          break;
      }
    },
    //基地管理
    // 初始化地图
    async initAMap() {
      this.resMap = await AMap();
      this.map = new this.resMap.Map("mapWrap", {
        zoom: this.zoom, //初始化地图层级
        center: this.mapCenter //初始化地图中心点
      });
      // // 添加点标记
      // this.addMarker();
    },
    toApp() {
      this.$router.push({
        path: "/downLoad"
      });
    },
    toBaseList() {
      this.$router.push({
        // path: "/baseList"
        path: "/baseAchievement"
      });
    },
    toDetail() {
      this.$router.push({
        path: "/nanFanMajorList"
      });
    },

    // 跳转至公文管理
    toDocument() {
      this.$router.push({
        path: "/officialDocument"
      });
    },
    toMonitor() {
      this.$router.push({
        path: "/Biological"
      });
    },
    toProject(id) {
      this.$router.push({
        path: "/nanFanMajorDetails",
        query: {
          id
        }
      });
    },
    getDay(day) {
      // 获取近一周、近一个月的日期
      const today = new Date();
      const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      const tYear = today.getFullYear();
      let tMonth = today.getMonth();
      let tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      let m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    //获取当前时间
    timestampToTime() {
      const date = new Date(),
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ";
      return Y + M + D;
    },
    monitoringdata() {
      this.radio = 0;
      if (this.timeData != null) {
        let monthe = this.timeData[0].getMonth() + 1;
        let mondatea = this.timeData[0].getDate();
        if (monthe < 10) {
          monthe = "0" + monthe;
        }
        if (mondatea < 10) {
          mondatea = "0" + mondatea;
        }
        this.startDates =
          this.timeData[0].getFullYear() + "-" + monthe + "-" + mondatea;
        let monthse = this.timeData[1].getMonth() + 1;
        let mondatesa = this.timeData[1].getDate();
        if (monthse < 10) {
          monthse = "0" + monthse;
        }
        if (mondatesa < 10) {
          mondatesa = "0" + mondatesa;
        }
        this.endDates =
          this.timeData[1].getFullYear() + "-" + monthse + "-" + mondatesa;
      } else {
        this.startDates = "";
        this.endDates = "";
      }
      this.selectMonitorSurvey();
    },
    // 生物监控数据
    async selectMonitorSurvey() {
      const api = this.$fetchApi.selectMonitorSurvey.api;
      const data = await this.$fetchData.fetchPost(
        {
          endDate: this.endDates,
          userId: "",
          startDate: this.startDates
        },
        api,
        "json"
      );
      if (data.code === "200" && data.result) {
        this.monitoring = data.result;
        for (const i in this.monitoring) {
          switch (this.monitoring[i].monitorType) {
            case 1:
              this.monitoring[i].name = "转基因生物安全监管";
              this.monitoring[
                i
              ].img = require("../../../public/static/img/management/icon_sujk1@2x.png");
              break;
            case 2:
              this.monitoring[i].name = "重大病虫草鼠害防治";
              this.monitoring[
                i
              ].img = require("../../../public/static/img/management/icon_sujk2@2x.png");
              break;
            case 3:
              this.monitoring[i].name = "外来入侵生物预警与控制";
              this.monitoring[
                i
              ].img = require("../../../public/static/img/management/icon_sujk4@2x.png");
              break;
            case 4:
              this.monitoring[i].name = "检疫性有害生物调查";
              this.monitoring[
                i
              ].img = require("../../../public/static/img/management/icon_sujk5@2x.png");
              break;
            case 5:
              this.monitoring[i].name = "生物安全普查";
              this.monitoring[
                i
              ].img = require("../../../public/static/img/management/icon_sujk3@2x.png");
              break;
          }
        }
        // console.log(this.monitoring);
      }
    },
    //重点项目
    async getKeyProgramAppByPage() {
      const api = this.$fetchApi.getKeyProgramAppByPage.api;
      const data = await this.$fetchData.fetchPost(
        {
          condition: "",
          endDate: "",
          id: "",
          pageNum: 1,
          pageSize: 9,
          startDate: "",
          status: "",
          type: "",
          userId: "",
          userType: 0
        },
        api,
        "json"
      );
      if (data.code === "200" && data.result) {
        this.projectList = data.result.rows;
        // console.log(this.projectList);
      }
    },
    //南繁人员
    // async queryForEchart() {
    //   const api = this.$fetchApi.queryForEchart.api;
    //   const data = await this.$fetchData.fetchPost({}, api, "json");
    //   if (data.code === "200" && data.result) {
    //     this.totalCount = data.result.titleCount[0].count;
    //     this.outCount = data.result.titleCount[1].count;
    //     this.stayCount = data.result.titleCount[2].count;
    //     this.focusCount = data.result.titleCount[3].count;
    //     const datalist = data.result.roleCount;
    //     for (const i in datalist) {
    //       const nowlist = datalist[i].count.split(",");
    //       this.namelist.push(nowlist[0]);
    //       this.countlist.push({ value: nowlist[1], name: nowlist[0] });
    //     }
    //     // console.log(this.namelist, this.countlist);
    //     const typeData = [];
    //     for (let i = 0, len = this.namelist.length; i < len; i += 2) {
    //       typeData.push(this.namelist.slice(i, i + 2));
    //     }
    //     typeData.forEach(item => {
    //       item.push("");
    //     });
    //     this.namelist = [].concat.apply([], typeData);
    //     this.drawecharts(this.namelist, this.countlist, this.totalCount);
    //   }
    // },
    //基地风采
    async getbaseDetailList() {
      const req = {
        baseName: "",
        cityId: "",
        companyId: "",
        districtId: "",
        keywords: "",
        pageNum: 1,
        pageSize: 10,
        provinceId: "",
        villageId: ""
      };
      const api = this.$fetchApi.baseDetailList.api;
      const data = await this.$fetchData.fetchPost(req, api, "json");
      if (data.code === "200" && data.result) {
        console.log(data.result.rows);
      }
    },
    drawecharts(name, count, total) {
      const charts = document.getElementById("charts");
      this.$echarts.dispose(charts);
      const echart = this.$echarts.init(charts);
      echart.setOption({
        title: {
          text: "总数",
          left: "center",
          top: "33.33%",
          padding: [24, 0],
          textStyle: {
            color: "#333333",
            fontSize: 25,
            align: "center"
          },
          subtext: "\n" + total + "\n\n人",
          subtextStyle: {
            color: "#333333",
            fontSize: 25,
            align: "center"
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)"
        },
        color: [
          "#f5777a",
          "#8db5cb",
          "#fea68b",
          "#7c8fb9",
          "#ffc363",
          "#475b91",
          "#fee562",
          "#99d9da",
          "#a1d45e",
          "#a9decb",
          "#76f8ca",
          "#afc396",
          "#54f6fb",
          "#ebe1ac",
          "#295be0",
          "#dfc7a0",
          "#5f93f8",
          "#d7b3a9",
          "#5fc3fc",
          "#c08e8f",
          "#fc6134",
          "#ff5be2",
          "#0b16cc",
          "#5bb218"
        ],
        legend: {
          x: "left",
          y: "center",
          data: name
        },
        series: [
          {
            name: "人数",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true,
                formatter: "{d}%",
                textStyle: {
                  fontSize: "20",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: {
              normal: {
                show: true
              }
            },
            data: count
          }
        ]
      });
    }
  }
};
</script>
<style>
</style>
